import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_2 = { class: "card-title m-0" }
const _hoisted_3 = { class: "symbol symbol-45px w-45px bg-light me-5" }
const _hoisted_4 = {
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0"
}
const _hoisted_5 = { class: "card-toolbar m-0" }
const _hoisted_6 = {
  type: "button",
  class: "\n            btn\n            btn-clean\n            btn-sm\n            btn-icon\n            btn-icon-primary\n            btn-active-light-primary\n            me-n3\n          ",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_7 = { class: "svg-icon svg-icon-3 svg-icon-primary" }
const _hoisted_8 = { class: "card-body d-flex flex-column px-9 pt-6 pb-8" }
const _hoisted_9 = { class: "fs-2tx fw-bolder mb-3" }
const _hoisted_10 = { class: "d-flex align-items-center flex-wrap mb-5 mt-auto fs-6" }
const _hoisted_11 = { class: "fw-bold text-gray-400" }
const _hoisted_12 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_13 = { class: "badge bg-light text-gray-700 px-3 py-2 me-2" }
const _hoisted_14 = { class: "text-gray-400 fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!

  return (_openBlock(), _createBlock("div", {
    class: ["card h-100", _ctx.cardClasses]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("img", {
            src: _ctx.icon,
            alt: "image",
            class: "p-3"
          }, null, 8, ["src"])
        ]),
        _createVNode("a", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("button", _hoisted_6, [
          _createVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown3)
      ])
    ]),
    _createVNode("div", _hoisted_8, [
      _createVNode("div", _hoisted_9, _toDisplayString(_ctx.content), 1),
      _createVNode("div", _hoisted_10, [
        _createVNode("span", {
          class: [`svg-icon-${_ctx.color}`, "svg-icon svg-icon-3 me-1"]
        }, [
          _createVNode(_component_inline_svg, {
            src: `media/icons/duotune/Navigation/${_ctx.arrow}.svg`
          }, null, 8, ["src"])
        ], 2),
        _createVNode("div", {
          class: [`text-${_ctx.color}`, "fw-bolder me-2"]
        }, _toDisplayString(_ctx.footerData), 3),
        _createVNode("div", _hoisted_11, _toDisplayString(_ctx.footerText), 1)
      ]),
      _createVNode("div", _hoisted_12, [
        _createVNode("span", _hoisted_13, _toDisplayString(_ctx.indicatorValue), 1),
        _createVNode("span", _hoisted_14, _toDisplayString(_ctx.indicatorLabel), 1)
      ])
    ])
  ], 2))
}